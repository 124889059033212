import React, { useState, useEffect } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import SkeletonLoader from './SkeletonLoader';
import { motion, AnimatePresence } from 'framer-motion';

const Gallery = () => {
  const [photos, setPhotos] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const zoomRef = React.useRef(null);

  useEffect(() => {
    fetch('/galleryImages.json')
      .then((response) => response.json())
      .then((data) => {
        const photoArray = data.map((src, index) => ({
          src,
          alt: `Gallery Image ${index + 1}`,
        }));
        setPhotos(photoArray);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading images:', error);
        setLoading(false);
      });
  }, []);

  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
  };

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.07,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.4 } },
  };

  const displayedPhotos = showAll ? photos : photos.slice(0, 3);

  return (
    <div className="container mx-auto p-6 max-w-full">
      {/* Gallery Title */}
      <motion.div
        className="text-center mb-8"
        initial="hidden"
        animate="visible"
        variants={itemVariants}
      >
        <h2 className="text-4xl font-extrabold text-gray-900 dark:text-white">
          Gallery
        </h2>
        <p className="text-lg text-gray-700 dark:text-gray-300">
          A collection of my favorite pictures in no specific order.
        </p>
      </motion.div>

      {loading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <SkeletonLoader key={index} />
            ))}
        </div>
      ) : (
        <>
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <AnimatePresence>
              {displayedPhotos.map((photo, index) => (
                <motion.div
                  key={photo.src} // Use unique key
                  className="relative cursor-pointer overflow-hidden rounded-lg shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105"
                  onClick={() => {
                    // Find the correct index in the full photos array
                    const photoIndex = photos.findIndex(
                      (p) => p.src === photo.src
                    );
                    setCurrentIndex(photoIndex);
                    setOpen(true);
                  }}
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                <img
                  src={photo.src}
                  alt={photo.alt}
                  className="w-full h-96 object-cover"
                  loading="lazy" // Add this line
                />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
          <motion.div
            className="flex justify-center mt-4"
            initial="hidden"
            animate="visible"
            variants={itemVariants}
          >
            {!showAll && photos.length > 3 && (
              <button
                onClick={handleShowMore}
                className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-hover-primary dark:bg-accent dark:text-gray-900 dark:hover:bg-hover-accent transition-colors mr-4"
              >
                Show More
              </button>
            )}
            {showAll && (
              <button
                onClick={handleShowLess}
                className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-hover-primary dark:bg-accent dark:text-gray-900 dark:hover:bg-hover-accent transition-colors"
              >
                Show Less
              </button>
            )}
          </motion.div>
        </>
      )}

      {open && (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={photos}
          index={currentIndex}
          onIndexChange={setCurrentIndex}
          plugins={[Zoom]}
          zoom={{ ref: zoomRef, scrollToZoom: true }}
        />
      )}
    </div>
  );
};

export default Gallery;
