import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoon,
  faImage,
  faUser,
  faEnvelope,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

const SpeedDial = ({ toggleDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const actions = [
    {
      icon: faImage,
      label: 'Gallery',
      href: '#gallery',
    },
    {
      icon: faUser,
      label: 'About Me',
      href: '#bio',
    },
    {
      icon: faEnvelope,
      label: 'Contact',
      href: '#contact-register-status',
    },
    {
      icon: faMoon,
      label: 'Dark Mode',
      onClick: toggleDarkMode,
    },
  ];

  return (
    <div className="fixed right-6 bottom-6 z-50 flex flex-col items-center">
      {/* Actions */}
      <div
        className={`flex flex-col items-center space-y-4 mb-4 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        } transition-opacity duration-300`}
      >
        {actions.map((action, index) => (
          <div key={index} className="flex items-center">
            {action.href ? (
              <a
                href={action.href}
                className="relative flex items-center justify-center w-12 h-12 bg-secondary dark:bg-secondary-dark text-primary dark:text-primary-dark rounded-full shadow-lg transform transition-transform hover:scale-105 focus:outline-none"
                aria-label={action.label}
              >
                <FontAwesomeIcon icon={action.icon} className="w-5 h-5" />
                <span className="absolute right-14 whitespace-nowrap text-sm font-medium text-primary dark:text-primary-dark bg-secondary dark:bg-secondary-dark px-2 py-1 rounded-md shadow-md">
                  {action.label}
                </span>
              </a>
            ) : (
              <button
                onClick={action.onClick}
                className="relative flex items-center justify-center w-12 h-12 bg-secondary dark:bg-secondary-dark text-primary dark:text-primary-dark rounded-full shadow-lg transform transition-transform hover:scale-105 focus:outline-none"
                aria-label={action.label}
              >
                <FontAwesomeIcon icon={action.icon} className="w-5 h-5" />
                <span className="absolute right-14 whitespace-nowrap text-sm font-medium text-primary dark:text-primary-dark bg-secondary dark:bg-secondary-dark px-2 py-1 rounded-md shadow-md">
                  {action.label}
                </span>
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Main FAB Button */}
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center text-white bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel rounded-full w-14 h-14 shadow-lg transform transition-transform hover:scale-105 focus:outline-none"
        aria-label="Open actions menu"
        aria-expanded={isOpen}
      >
        <FontAwesomeIcon
          icon={faPlus}
          className={`w-6 h-6 transform transition-transform ${
            isOpen ? 'rotate-45' : ''
          }`}
        />
      </button>
    </div>
  );
};

export default SpeedDial;
