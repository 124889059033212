import React from 'react';

const PersonalTimeline = () => {
  const events = [
    {
      year: "July 2021 - August 2022",
      description: "Shift Lead at Nothing Bundt Cakes. Performed various roles including frosting, baking, guest services, and utility. Also led marketing efforts with outreach to local businesses.",
      image: "img1.JPEG",
      isCircle: true,
      position: 'right'
    },
    {
      year: "July 2022",
      description: "Customer Service Representative at Citi. Provided comprehensive support for credit card accounts, handling tasks such as processing payments, issuing replacement cards, and resolving customer disputes. Managed full-time work alongside school commitments.",
      image: "img2.JPEG",
      isCircle: true,
      position: 'left'
    },
    {
      year: "Present Date",
      description: "Currently a part-time Customer Service Representative at Citi. Continue to assist customers with their accounts,and addressing any issues, while accommodating my academic schedule.",
      image: "img3.JPEG",
      isCircle: false,
      position: 'right'
    }
  ];

  return (
    <div className="container mx-auto p-4 md:p-6 bg-secondary dark:bg-secondary-dark text-primary dark:text-primary-dark font-sans">
      <div className="relative wrap overflow-hidden p-2 md:p-10">
        <div className="absolute border-2 border-muted dark:border-muted-dark left-1/2 transform -translate-x-1/2" style={{ height: '100%' }}></div>
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full flex flex-col justify-between">
          {events.map((_, index) => (
            <div key={index} className="rounded-full bg-hunyadiYellow w-2 md:w-4 lg:w-8 h-2 md:h-4 lg:h-8 -translate-y-1/2"></div>
          ))}
        </div>
        {events.map((event, index) => (
          <div key={index} className={`mb-10 md:mb-16 flex ${event.position === 'left' ? 'flex-row' : 'flex-row-reverse'} items-center`}>
            <div className="w-full md:w-5/12 px-2 py-2 md:px-4 md:py-6">
              <div className="relative">
                <img 
                  src={`/TimelineImages/ProfessionalTimeline/${event.image}`} 
                  alt={`${event.year}`} 
                  className={`mt-8 md:mt-12 ${event.isCircle ? 'rounded-full' : 'rounded-lg'} shadow-lg w-full h-auto`} 
                />
                <div className={`absolute bottom-8 left-0 right-0 transform translate-y-1/2 bg-accent dark:bg-accent-dark bg-opacity-90 rounded-lg p-2 md:p-4 text-primary dark:text-primary-dark backdrop-filter backdrop-blur-md hover:bg-hover-accent transition-all duration-300`}>
                  <h4 className="mb-0 text-base md:text-lg lg:text-2xl font-bold">{event.year}</h4>
                  <p className="text-xs md:text-sm lg:text-lg leading-snug tracking-wide">{event.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonalTimeline;
