import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faStarHalfAlt,
  faUsers,
  faUserPlus,
  faHeart,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import SkeletonLoaderTimeline from './SkeletonLoaderTimeline';

const GameModShowcase = () => {
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const totalImages = 3; // Total number of images to load

  // Track the loading state of all images
  const handleImageLoad = () => {
    setImagesLoaded((prev) => prev + 1);
  };

  useEffect(() => {
    if (imagesLoaded === totalImages) {
      setLoading(false);
    }
  }, [imagesLoaded]);

  // Animation Variants
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const staggerChildren = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <div className="container mx-auto py-12">
      {loading && <SkeletonLoaderTimeline />}
      <div className={`${loading ? 'hidden' : 'block'}`}>
        {/* Header Section */}
        <motion.div
          className="text-center mb-16"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <motion.h1
            className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel mb-6"
            variants={fadeInUp}
          >
            Ark: SE Glowing Armor Mod
          </motion.h1>
          <motion.p
            className="text-xl text-primary dark:text-primary-dark max-w-3xl mx-auto"
            variants={fadeInUp}
          >
            With the Glowing Armor Mod, your Ark experience is not just enhanced; it's transformed. Illuminate your adventures with gear that reflects your personality and stands out in any environment.
          </motion.p>
        </motion.div>

        {/* Stats Section */}
        <motion.div
          className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-16 text-center"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            className="flex flex-col items-center text-yellow-500 dark:text-yellow-400"
            variants={fadeInUp}
          >
            <div className="flex space-x-1">
              <FontAwesomeIcon icon={faStar} className="text-2xl" />
              <FontAwesomeIcon icon={faStar} className="text-2xl" />
              <FontAwesomeIcon icon={faStar} className="text-2xl" />
              <FontAwesomeIcon icon={faStar} className="text-2xl" />
              <FontAwesomeIcon icon={faStarHalfAlt} className="text-2xl" />
            </div>
            <p className="text-lg mt-2 text-primary dark:text-primary-dark">4.5/5</p>
          </motion.div>
          <motion.div
            className="flex flex-col items-center text-accent dark:text-hunyadiYellow"
            variants={fadeInUp}
          >
            <FontAwesomeIcon icon={faUsers} className="text-3xl mb-2" />
            <p className="text-lg text-primary dark:text-primary-dark">
              19,000+ Unique Visitors
            </p>
          </motion.div>
          <motion.div
            className="flex flex-col items-center text-accent dark:text-hunyadiYellow"
            variants={fadeInUp}
          >
            <FontAwesomeIcon icon={faUserPlus} className="text-3xl mb-2" />
            <p className="text-lg text-primary dark:text-primary-dark">
              18,000+ Total Subscribers
            </p>
          </motion.div>
          <motion.div
            className="flex flex-col items-center text-accent dark:text-hunyadiYellow"
            variants={fadeInUp}
          >
            <FontAwesomeIcon icon={faHeart} className="text-3xl mb-2" />
            <p className="text-lg text-primary dark:text-primary-dark">545+ Favorites</p>
          </motion.div>
        </motion.div>

        {/* Content Sections */}
        <motion.div
          className="space-y-16"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Section 1 */}
          <motion.div
            className="flex flex-col md:flex-row items-center md:space-x-12"
            variants={fadeInUp}
          >
            <div className="w-full md:w-1/2">
              <motion.img
                src="/TimelineImages/ProjectsTimeline/GameModShowcase/img1.JPEG"
                alt="Mod Showcase"
                className="rounded-lg shadow-lg w-full h-auto"
                onLoad={handleImageLoad}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>
            <div className="w-full md:w-1/2 mt-8 md:mt-0 text-primary dark:text-primary-dark">
              <motion.h2
                className="text-3xl font-bold mb-4"
                variants={fadeInUp}
              >
                What Does My Mod Do?
              </motion.h2>
              <motion.p
                className="text-lg mb-4"
                variants={fadeInUp}
              >
                <strong>Glowing Armor Features:</strong> This mod adds a crafting bench used to make special glowing armor! The variety of colors adds a touch of personality and a splash of color to your gear.
              </motion.p>
              <motion.p
                className="text-lg"
                variants={fadeInUp}
              >
                <strong>What Does the Armor Do?</strong> It glows. There are several different colors of glowing armor to choose from.
              </motion.p>
            </div>
          </motion.div>

          {/* Section 2 */}
          <motion.div
            className="flex flex-col md:flex-row items-center md:space-x-12"
            variants={fadeInUp}
          >
            <div className="w-full md:w-1/2 order-2 md:order-1 mt-8 md:mt-0 text-primary dark:text-primary-dark">
              <motion.h2
                className="text-3xl font-bold mb-4"
                variants={fadeInUp}
              >
                What is Ark?
              </motion.h2>
              <motion.p
                className="text-lg mb-4"
                variants={fadeInUp}
              >
                <strong>ARK: Survival Evolved Modding:</strong> ARK: Survival Evolved is a multiplayer survival game where players must survive on an island filled with dinosaurs and other prehistoric creatures. Modding in ARK allows for extensive customization, from new items and creatures to entire gameplay overhauls.
              </motion.p>
              <motion.p
                className="text-lg mb-4"
                variants={fadeInUp}
              >
                <strong>Creating Mods for ARK:</strong> Creating mods for ARK involves using Unreal Engine's Blueprint system, a visual scripting language that requires knowledge of game development principles and creativity to bring new ideas to life.
              </motion.p>
              <motion.p
                className="text-lg"
                variants={fadeInUp}
              >
                <strong>Technical Skills Highlight:</strong> During the development of this mod, I utilized several key technical skills, including:
              </motion.p>
              <motion.ul
                className="list-disc ml-6 mt-2 text-lg"
                variants={fadeInUp}
              >
                <li><strong>Unreal Engine:</strong> Mastery of Unreal Engine's Blueprint system for scripting game logic and creating custom functionalities.</li>
                <li><strong>3D Modeling:</strong> Skills in 3D modeling software to design and integrate custom assets into the game.</li>
                <li><strong>Texture Mapping:</strong> Proficiency in texture mapping to create visually appealing and optimized textures for the glowing armor.</li>
                <li><strong>Version Control:</strong> Use of version control systems to publish my mod to the Steam Workshop.</li>
              </motion.ul>
            </div>
            <div className="w-full md:w-1/2 order-1 md:order-2">
              <motion.img
                src="/TimelineImages/ProjectsTimeline/GameModShowcase/img2.JPEG"
                alt="ARK: Survival Evolved Logo"
                className="rounded-lg shadow-lg w-full h-auto"
                onLoad={handleImageLoad}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>
          </motion.div>

          {/* Section 3 */}
          <motion.div
            className="flex flex-col md:flex-row items-center md:space-x-12"
            variants={fadeInUp}
          >
            <div className="w-full md:w-1/2">
              <motion.img
                src="/TimelineImages/ProjectsTimeline/GameModShowcase/img3.JPEG"
                alt="Nitrado Mod Program"
                className="rounded-lg shadow-lg w-full h-auto"
                onLoad={handleImageLoad}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>
            <div className="w-full md:w-1/2 mt-8 md:mt-0 text-primary dark:text-primary-dark">
              <motion.h2
                className="text-3xl font-bold mb-4"
                variants={fadeInUp}
              >
                Sponsored Mod Program
              </motion.h2>
              <motion.p
                className="text-lg mb-4"
                variants={fadeInUp}
              >
                <strong>Nitrado Mod Program:</strong> Through this mod, I had the opportunity to join Nitrado's mod program, which provided free server hosting during development.
              </motion.p>
              <motion.p
                className="text-lg mb-6"
                variants={fadeInUp}
              >
                <strong>Server Hosting:</strong> This allowed me to deploy my mod to my own game server that I hosted for other players to play on.
              </motion.p>
              <motion.a
                href="https://steamcommunity.com/sharedfiles/filedetails/?id=1540071951"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel rounded-full shadow-lg transform transition-transform hover:scale-105 focus:outline-none"
                variants={fadeInUp}
              >
                <FontAwesomeIcon icon={faSteam} className="w-5 h-5 mr-3" />
                Check it out on Steam Workshop
              </motion.a>
            </div>
          </motion.div>
        </motion.div>

        {/* Scroll Down Indicator (Optional) */}
        <motion.div
          className="flex justify-center mt-16"
          animate={{ y: [0, 10, 0] }}
          transition={{ repeat: Infinity, duration: 2 }}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className="text-accent dark:text-hunyadiYellow text-3xl"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default GameModShowcase;
