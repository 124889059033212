import React, { useRef, useEffect, useState } from 'react';
import p5 from 'p5';

const FallingSandSimulator = () => {
  const sketchRef = useRef();
  const sandColorRef = useRef('#FFA500'); // Default sand color
  const gravityDefyingRef = useRef(false); // Ref to track sand toggle state
  const [sandColor, setSandColor] = useState('#FFA500'); // Default to orange for all sand
  const [useGravityDefyingSand, setUseGravityDefyingSand] = useState(false); // Toggle between sand types
  const [p5Instance, setP5Instance] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // State to control modal open/close
  const [gridSize, setGridSize] = useState(5); // State to control grid size

  // Function to create a 2D array
  const make2DArray = (cols, rows) => {
    return Array.from({ length: cols }, () => Array(rows).fill(null));
  };

  // Initialize the sketch
  const initializeSketch = (size) => {
    const sketch = (p) => {
      let grid;
      let w = size; // Dynamic grid size
      let cols, rows;
      let removeBottomLayerFlag = false; // Flag to remove bottom layer
      let checkForSettling = false; // Flag to start checking if sand has settled

      // Set up the canvas and grid
      p.setup = () => {
        const width = sketchRef.current.offsetWidth;
        const height = sketchRef.current.offsetHeight; // Set height dynamically based on container
        p.createCanvas(width, height);
        p.noStroke();
        cols = p.floor(width / w);
        rows = p.floor(height / w) + 1; // Increase rows by 1 for hidden bottom layer
        grid = make2DArray(cols, rows);
        p.bottomLayerPresent = true; // Initialize bottom layer presence
        p.addBottomLayer(); // Add bottom layer at setup
      };

      // Function to add bottom layer
      p.addBottomLayer = function () {
        for (let i = 0; i < cols; i++) {
          grid[i][rows - 1] = { type: 'fixedSand', color: sandColorRef.current };
        }
        p.bottomLayerPresent = true;
      };

      // Function to remove bottom layer and all gravity-defying sand
      p.removeBottomLayer = function () {
        for (let i = 0; i < cols; i++) {
          for (let j = 0; j < rows; j++) {
            if (grid[i][j] && grid[i][j].type === 'fixedSand') {
              grid[i][j] = null;
            }
          }
        }
        p.bottomLayerPresent = false;
      };

      // Function to set the remove bottom layer flag
      p.setRemoveBottomLayerFlag = function (value) {
        removeBottomLayerFlag = value;
        if (value) {
          checkForSettling = true; // Start checking for sand settling
        }
      };

      // Handle window resizing
      p.windowResized = () => {
        const width = sketchRef.current.offsetWidth;
        const height = sketchRef.current.offsetHeight;
        p.resizeCanvas(width, height);
        cols = p.floor(width / w);
        rows = p.floor(height / w) + 1; // Increase rows by 1 for hidden bottom layer
        grid = make2DArray(cols, rows);
        p.addBottomLayer(); // Re-add bottom layer after resizing
      };

      // Function to place sand based on the current mode
      const placeSand = (col, row) => {
        if (col >= 0 && col < cols && row >= 0 && row < rows - 1) {
          if (gravityDefyingRef.current) {
            // Place gravity-defying sand (acts as a barrier)
            grid[col][row] = { type: 'fixedSand', color: sandColorRef.current }; // Gravity-defying sand stays fixed
          } else {
            // Place regular falling sand
            grid[col][row] = { type: 'gravitySand', color: sandColorRef.current }; // Regular sand follows gravity
          }
        }
      };

      // Function to check if all sand has settled
      const isSandSettled = () => {
        for (let i = 0; i < cols; i++) {
          for (let j = 0; j < rows - 1; j++) {
            const cell = grid[i][j];
            if (cell) {
              return false; // Sand particle still present
            }
          }
        }
        return true; // No sand particles left
      };

      // Main draw loop
      p.draw = () => {
        // Remove bottom layer if the flag is set
        if (removeBottomLayerFlag) {
          p.removeBottomLayer();
          removeBottomLayerFlag = false; // Reset the flag
        }

        p.background(25, 25, 25);

        // Display the current grid (excluding the bottom layer)
        for (let i = 0; i < cols; i++) {
          for (let j = 0; j < rows - 1; j++) {
            const cell = grid[i][j];
            p.fill(cell ? cell.color : 25); // Use the cell's stored color or background
            p.square(i * w, j * w, w);
          }
        }

        // Create a new grid for the next frame
        const nextGrid = make2DArray(cols, rows);

        // Handle continuous sand placement while the mouse is pressed
        if (p.mouseIsPressed) {
          const col = p.floor(p.mouseX / w);
          const row = p.floor(p.mouseY / w);
          placeSand(col, row);
        }

        // Iterate over the grid and apply gravity to regular sand
        for (let i = 0; i < cols; i++) {
          for (let j = rows - 1; j >= 0; j--) {
            const cell = grid[i][j];
            if (cell) {
              if (cell.type === 'gravitySand') {
                // Handle gravity for regular sand
                if (j < rows - 1 && !grid[i][j + 1]) {
                  nextGrid[i][j + 1] = cell; // Move down if below is empty
                } else if (j < rows - 1) {
                  // Check diagonal movement
                  const canMoveLeft =
                    i > 0 && !grid[i - 1][j + 1];
                  const canMoveRight =
                    i < cols - 1 && !grid[i + 1][j + 1];

                  if (canMoveLeft && canMoveRight) {
                    if (Math.random() < 0.5) {
                      nextGrid[i - 1][j + 1] = cell;
                    } else {
                      nextGrid[i + 1][j + 1] = cell;
                    }
                  } else if (canMoveLeft) {
                    nextGrid[i - 1][j + 1] = cell;
                  } else if (canMoveRight) {
                    nextGrid[i + 1][j + 1] = cell;
                  } else {
                    nextGrid[i][j] = cell; // Stay in place if blocked
                  }
                } else if (j === rows - 1) {
                  // Sand has fallen out of the grid
                  // Do not add to nextGrid
                } else {
                  nextGrid[i][j] = cell; // Stay in place if at the bottom
                }
              } else if (cell.type === 'fixedSand') {
                // Gravity-defying sand stays in place
                nextGrid[i][j] = cell;
              }
            }
          }
        }

        // Update the grid for the next frame
        grid = nextGrid;

        // Check if we need to re-add the bottom layer
        if (checkForSettling) {
          if (isSandSettled()) {
            p.addBottomLayer();
            checkForSettling = false; // Reset the flag
          }
        }
      };
    };

    // Clean up any existing instance
    if (p5Instance) {
      p5Instance.remove();
    }

    // Create a new p5 instance
    const instance = new p5(sketch, sketchRef.current);
    setP5Instance(instance);
  };

  // Reinitialize the sketch when the component opens or grid size changes
  useEffect(() => {
    if (isOpen) {
      initializeSketch(gridSize);
    }
  }, [isOpen, gridSize]);

  // Update the sand color and gravity-defying toggle
  useEffect(() => {
    sandColorRef.current = sandColor; // Update sand color for both types
    gravityDefyingRef.current = useGravityDefyingSand; // Sync toggle state
  }, [sandColor, useGravityDefyingSand]);

  // Prevent page scrolling when interacting with the canvas
  useEffect(() => {
    const preventDefaultScroll = (e) => {
      if (e.target.closest('#sketchCanvas')) e.preventDefault();
    };

    if (isOpen) window.addEventListener('touchmove', preventDefaultScroll, { passive: false });
    return () => window.removeEventListener('touchmove', preventDefaultScroll);
  }, [isOpen]);

  // Increase grid size
  const increaseGridSize = () => setGridSize((prevSize) => Math.min(prevSize + 1, 50));

  // Decrease grid size
  const decreaseGridSize = () => setGridSize((prevSize) => Math.max(prevSize - 1, 1));

  return (
    <>
      <section id="simulator-button" className="py-16 text-center">
        <button
          className="bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel hover:from-orangePeel hover:to-accent text-white font-bold py-3 px-6 rounded-full shadow-xl transform transition-transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-accent"
          onClick={() => setIsOpen(true)}
        >
          Open Falling Sand Simulator
        </button>
      </section>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="bg-secondary dark:bg-secondary-dark p-4 rounded-lg shadow-lg relative w-full max-w-lg mx-4"
            style={{ maxHeight: '95vh', overflow: 'hidden' }} // Keep the modal height within 95% of the viewport height
          >
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 focus:outline-none text-3xl"
              onClick={() => setIsOpen(false)}
              style={{
                fontSize: '2rem',
                color: '#FF0000', // Red color for visibility
                cursor: 'pointer',
                zIndex: 1000,
              }}
            >
              &times;
            </button>

            {/* Sand Type Toggle */}
            <div className="mb-2 flex items-center justify-center">
              <label className="mr-4 text-primary dark:text-primary-dark font-bold text-lg">
                Use Gravity-Defying Sand:
              </label>
              <input
                type="checkbox"
                id="sandTypeToggle"
                checked={useGravityDefyingSand}
                onChange={(e) => setUseGravityDefyingSand(e.target.checked)}
                className="w-6 h-6 cursor-pointer"
              />
            </div>

            {/* Color Picker */}
            <div className="mb-2 flex items-center justify-center">
              <label
                htmlFor="colorPicker"
                className="mr-4 text-primary dark:text-primary-dark font-bold text-lg"
              >
                Select Sand Color:
              </label>
              <input
                type="color"
                id="colorPicker"
                value={sandColor}
                onChange={(e) => setSandColor(e.target.value)}
                className="w-10 h-10 p-0 border-0 cursor-pointer"
              />
            </div>

            {/* Grid Size Buttons */}
            <div className="mb-2 flex items-center justify-center">
              <label className="mr-4 text-primary dark:text-primary-dark font-bold text-lg">
                Grid Size:
              </label>
              <div className="flex items-center space-x-2">
                <button
                  onClick={decreaseGridSize}
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-1 px-3 rounded-full"
                >
                  -
                </button>
                <span className="text-lg font-bold text-primary dark:text-primary-dark">
                  {gridSize}
                </span>
                <button
                  onClick={increaseGridSize}
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-1 px-3 rounded-full"
                >
                  +
                </button>
              </div>
            </div>

            {/* Reset Button */}
            <div className="mb-2 text-center">
              <button
                className="mt-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 text-lg"
                onClick={() => {
                  if (p5Instance) {
                    p5Instance.setRemoveBottomLayerFlag(true); // Remove bottom layer and gravity-defying sand on reset
                  }
                }}
              >
                Reset Simulation
              </button>
            </div>

            {/* Canvas */}
            <div
              ref={sketchRef}
              id="sketchCanvas"
              className="w-full mx-auto"
              style={{
                width: '100%', // Fullscreen canvas
                height: '40vh', // Limit the canvas height to 40% of the viewport height
                overflow: 'hidden',
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default FallingSandSimulator;
