import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Bio = () => {
  return (
    <div className="p-8 bg-secondary dark:bg-secondary-dark rounded-lg shadow-lg text-center">
      <h2 className="text-5xl font-extrabold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel">
        About Me
      </h2>
      <p className="text-xl mb-6 text-primary dark:text-primary-dark">
        Hi, I'm Gavin Gizzie. I enjoy hiking, snowboarding, and exploring the
        outdoors. I just recently was gifted a camera and have been playing
        around with it. I'm a junior in college this year and a member of my
        university's Sigma Chi chapter.
      </p>
      <h3 className="text-4xl font-extrabold mb-4 text-primary dark:text-primary-dark">
        Contact Me
      </h3>
      <p className="text-xl mb-6 text-primary dark:text-primary-dark">
        Email:{' '}
        <a
          href="mailto:gavin@gavingizzie.com"
          className="text-accent dark:text-hunyadiYellow hover:underline"
        >
          gavin@gavingizzie.com
        </a>
      </p>
      <h3 className="text-4xl font-extrabold mb-4 text-primary dark:text-primary-dark">
        Connect with Me
      </h3>
      <div className="flex justify-center gap-8 mt-4">
        <a
          href="https://www.instagram.com/gavin_gizzie/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-2xl text-accent dark:text-hunyadiYellow hover:text-hover-accent dark:hover:text-hover-hunyadiYellow transition-colors"
        >
          <FontAwesomeIcon icon={faInstagram} className="mr-2" size="2x" />
          Instagram
        </a>
        <a
          href="https://www.linkedin.com/in/gavingizzie/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-2xl text-accent dark:text-hunyadiYellow hover:text-hover-accent dark:hover:text-hover-hunyadiYellow transition-colors"
        >
          <FontAwesomeIcon icon={faLinkedin} className="mr-2" size="2x" />
          LinkedIn
        </a>
      </div>
    </div>
  );
};

export default Bio;
