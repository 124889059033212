import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const Footer = () => {
  return (
    <footer className="bg-secondary dark:bg-secondary-dark text-primary dark:text-primary-dark py-8 relative">
      <div className="container mx-auto px-4">
        {/* Top Section */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Brand Name */}
          <div className="mb-6 md:mb-0">
            <a
              href="#"
              className="text-2xl font-bold text-accent dark:text-accent-dark hover:text-hover-accent dark:hover:text-hover-accent-dark transition-colors"
            >
              Gavin Gizzie
            </a>
          </div>

          {/* Navigation Links */}
          <div className="flex space-x-6">
            <motion.a
              href="#bio"
              className="text-primary dark:text-primary-dark hover:text-accent dark:hover:text-accent-dark transition-colors"
              whileHover={{ scale: 1.1 }}
            >
              About
            </motion.a>
            <motion.a
              href="#timeline-tabs"
              className="text-primary dark:text-primary-dark hover:text-accent dark:hover:text-accent-dark transition-colors"
              whileHover={{ scale: 1.1 }}
            >
              Projects
            </motion.a>
            <motion.a
              href="#contact-register-status"
              className="text-primary dark:text-primary-dark hover:text-accent dark:hover:text-accent-dark transition-colors"
              whileHover={{ scale: 1.1 }}
            >
              Contact
            </motion.a>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-muted dark:border-muted-dark my-6"></div>

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Social Media Links */}
          <div className="flex space-x-6 mb-6 md:mb-0">
            <motion.a
              href="https://www.instagram.com/gavin_gizzie/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary dark:text-primary-dark hover:text-accent dark:hover:text-accent-dark transition-colors"
              aria-label="Instagram"
              whileHover={{ scale: 1.2, rotate: 10 }}
            >
              <FontAwesomeIcon icon={faInstagram} className="w-6 h-6" />
            </motion.a>
            <motion.a
              href="https://www.linkedin.com/in/gavingizzie/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary dark:text-primary-dark hover:text-accent dark:hover:text-accent-dark transition-colors"
              aria-label="LinkedIn"
              whileHover={{ scale: 1.2, rotate: -10 }}
            >
              <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6" />
            </motion.a>
          </div>

          {/* Copyright */}
          <div className="text-center md:text-right">
            <p className="text-sm">
              &copy; {new Date().getFullYear()} Gavin Gizzie. All rights reserved.
            </p>
          </div>
        </div>
      </div>

      {/* Back to Top Button */}
      <motion.button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-6 right-6 bg-accent dark:bg-accent-dark text-white p-3 rounded-full shadow-lg hover:bg-hover-accent dark:hover:bg-hover-accent-dark transition-colors"
        aria-label="Back to Top"
        whileHover={{ scale: 1.1 }}
      >
        <FontAwesomeIcon icon={faArrowUp} className="w-5 h-5" />
      </motion.button>
    </footer>
  );
};

export default Footer;
