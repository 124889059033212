import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import SkeletonLoaderTimeline from './SkeletonLoaderTimeline';
import { motion } from 'framer-motion';

const DPrintShowcase = () => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const containerVariants = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <div className="container mx-auto p-6 md:p-8 max-w-7xl bg-secondary dark:bg-gray-800 rounded-lg">
      {loading && <SkeletonLoaderTimeline />}
      <motion.div
        style={{ display: loading ? 'none' : 'block' }}
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
        <motion.div className="text-center my-8" variants={itemVariants}>
          <h1 className="text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            3D Printing Showcase
          </h1>
          <p className="mb-6 text-lg font-normal text-gray-800 lg:text-xl sm:px-12 xl:px-32 dark:text-gray-300">
            Explore my collection of 3D printed projects.
          </p>
        </motion.div>

        {/* First Row: Images 1 and 2 with content */}
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 items-center"
          variants={itemVariants}
        >
          <motion.div className="flex space-x-4" variants={itemVariants}>
            <motion.img
              src="/TimelineImages/ProjectsTimeline/3dPrintShowcase/img1.JPEG"
              alt="3D Printer - Elegoo Neptune 3 Pro"
              className="rounded-lg shadow-md object-cover h-72 cursor-pointer w-1/2"
              onClick={() => handleImageClick(0)}
              onLoad={handleImageLoad}
              variants={itemVariants}
            />
            <motion.img
              src="/TimelineImages/ProjectsTimeline/3dPrintShowcase/img2.JPEG"
              alt="3D Printer in action"
              className="rounded-lg shadow-md object-cover h-72 cursor-pointer w-1/2"
              onClick={() => handleImageClick(1)}
              onLoad={handleImageLoad}
              variants={itemVariants}
            />
          </motion.div>
          <motion.div
            className="text-gray-900 dark:text-gray-200"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-bold">
              Current 3D Printer: Elegoo Neptune 3 Pro
            </h2>
            <ul className="list-disc ml-6 mt-4">
              <li>
                <strong>Build Volume:</strong> 220 x 220 x 250 mm
              </li>
              <li>
                <strong>Layer Resolution:</strong> 0.1-0.4 mm
              </li>
              <li>
                <strong>Filament Compatibility:</strong> PLA, ABS, PETG, TPU
              </li>
              <li>
                <strong>Special Features:</strong> Direct drive extruder, silent
                operation, auto bed leveling
              </li>
            </ul>
            <p className="mt-4">
              I use this printer for a variety of projects including some
              planters. It’s a great beginner printer and I picked it up used
              for around $100.
            </p>
          </motion.div>
        </motion.div>

        {/* Second Row: Images 3 and 4 with content */}
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 items-center"
          variants={itemVariants}
        >
          <motion.div
            className="text-gray-900 dark:text-gray-200 order-last md:order-first"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-bold">Stuff I've Made</h2>
            <p className="mt-4">
              Using CAD software, I designed a custom Roku remote holder that
              mounts to my desk, as well as a headset stand and an Xbox
              controller holder.
            </p>
          </motion.div>
          <motion.div className="flex space-x-4" variants={itemVariants}>
            <motion.img
              src="/TimelineImages/ProjectsTimeline/3dPrintShowcase/img3.JPEG"
              alt="3D Printed Flower Pots and Stands"
              className="rounded-lg shadow-md object-cover h-72 w-1/2 cursor-pointer"
              onClick={() => handleImageClick(2)}
              onLoad={handleImageLoad}
              variants={itemVariants}
            />
            <motion.img
              src="/TimelineImages/ProjectsTimeline/3dPrintShowcase/img4.JPEG"
              alt="Remote Holder"
              className="rounded-lg shadow-md object-cover h-72 w-1/2 cursor-pointer"
              onClick={() => handleImageClick(3)}
              onLoad={handleImageLoad}
              variants={itemVariants}
            />
          </motion.div>
        </motion.div>

        {/* Third Row: Image 5 with content */}
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 items-center"
          variants={itemVariants}
        >
          <motion.img
            src="/TimelineImages/ProjectsTimeline/3dPrintShowcase/img5.JPEG"
            alt="Anet A8 3D Printer"
            className="rounded-lg shadow-md object-cover h-72 cursor-pointer"
            onClick={() => handleImageClick(4)}
            onLoad={handleImageLoad}
            variants={itemVariants}
          />
          <motion.div
            className="text-gray-900 dark:text-gray-200"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-bold">My Old Printer: Anet A8</h2>
            <p className="mt-4">
              The Anet A8 was my first 3D printer, which I built from a kit when
              I was 11 years old. It introduced me to the world of 3D printing
              and hands-on electronics.
            </p>
            <p className="mt-4">
              I integrated a Raspberry Pi and camera to timelapse my prints and
              added a web interface to control the printer remotely. This setup
              allowed me to monitor and manage my prints from anywhere.
            </p>
          </motion.div>
        </motion.div>
      </motion.div>

      {open && (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={[
            {
              src: '/TimelineImages/ProjectsTimeline/3dPrintShowcase/img1.JPEG',
              alt: '3D Printer - Elegoo Neptune 3 Pro',
            },
            {
              src: '/TimelineImages/ProjectsTimeline/3dPrintShowcase/img2.JPEG',
              alt: '3D Printer in action',
            },
            {
              src: '/TimelineImages/ProjectsTimeline/3dPrintShowcase/img3.JPEG',
              alt: '3D Printed Flower Pots and Stands',
            },
            {
              src: '/TimelineImages/ProjectsTimeline/3dPrintShowcase/img4.JPEG',
              alt: 'Remote Holder',
            },
            {
              src: '/TimelineImages/ProjectsTimeline/3dPrintShowcase/img5.JPEG',
              alt: 'Anet A8 3D Printer',
            },
          ]}
          index={currentIndex}
          onIndexChange={setCurrentIndex}
          plugins={[Zoom]}
        />
      )}
    </div>
  );
};

export default DPrintShowcase;
