import React, { useState, useEffect } from 'react';
import Bio from './components/Bio';
import Gallery from './components/Gallery';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import SpeedDial from './components/SpeedDial';
import TimelineTabs from './components/TimelineTabs';
import FallingSandSimulator from './components/FallingSandSimulator';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const App = () => {
  // **State Variables**
  const [authStatus, setAuthStatus] = useState(null);

  // **Toggle Dark Mode Function**
  const toggleDarkMode = () => {
    document.documentElement.classList.toggle('dark');
  };

  // **Animation Variants**
  const headerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeOut' } },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1, ease: 'easeOut', delay: 0.5 },
    },
  };

  return (
    <div className="bg-secondary dark:bg-secondary-dark text-primary dark:text-primary-dark font-ivysoft min-h-screen">
      <motion.header
        className="relative h-screen bg-fixed bg-cover bg-center overflow-hidden"
        style={{
          backgroundImage: `url('homeimage.JPEG')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
        variants={headerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black via-30% to-primary dark:to-gray-800 opacity-70"></div>

        {/* Header Content */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-white text-center px-4">
          <motion.h1
            className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-extrabold mb-6 tracking-tight"
            variants={titleVariants}
          >
            <span className="bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel bg-clip-text text-transparent">
              Gavin Gizzie
            </span>
          </motion.h1>
          <motion.p
            className="text-xl sm:text-2xl md:text-3xl mb-10 font-semibold"
            variants={titleVariants}
            transition={{ delay: 0.3, duration: 1, ease: 'easeOut' }}
          >
            Welcome to my Website
          </motion.p>
          <motion.button
            className="bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel hover:from-orangePeel hover:to-accent text-white font-bold py-3 px-6 sm:py-4 sm:px-8 rounded-full flex items-center transition-transform transform hover:scale-110 shadow-xl focus:outline-none focus:ring-4 focus:ring-accent"
            onClick={() =>
              document.getElementById('gallery').scrollIntoView({ behavior: 'smooth' })
            }
            variants={buttonVariants}
          >
            View Gallery
            <FontAwesomeIcon icon={faArrowRight} className="w-5 h-5 sm:w-6 sm:h-6 ml-3" />
          </motion.button>
        </div>
      </motion.header>

      <main className="main-content">
        <section id="gallery" className="py-16">
          <Gallery />
        </section>
        <section id="bio" className="py-16 bg-gray-100 dark:bg-gray-900">
          <Bio />
        </section>
        <section id="timeline-tabs" className="py-16">
          <TimelineTabs />
        </section>
        <section id="falling-sand" className="py-16 bg-gray-100 dark:bg-gray-900">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-6 text-center">Falling Sand Simulator</h2>
            <FallingSandSimulator />
          </div>
        </section>
        <section
          id="contact"
          className="relative bg-fixed bg-cover bg-center py-16 overflow-hidden"
          style={{
            backgroundImage: `url('mountain_background.JPEG')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-900 opacity-80"></div>
          <div className="relative z-10 container mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-center items-stretch">
              <ContactForm />
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <SpeedDial toggleDarkMode={toggleDarkMode} />
    </div>
  );
};

export default App;
