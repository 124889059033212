import React from 'react';

const SkeletonLoaderTimeline = () => {
  const skeletons = [1, 2, 3];

  return (
    <div className="container mx-auto p-6 md:p-8 max-w-7xl bg-secondary dark:bg-gray-800 rounded-lg">
      <div className="relative wrap overflow-hidden p-2 md:p-10">
        <div className="absolute border-2 border-muted dark:border-muted-dark left-1/2 transform -translate-x-1/2" style={{ height: '100%' }}></div>
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full flex flex-col justify-between">
          {skeletons.map((_, index) => (
            <div key={index} className="rounded-full bg-hunyadiYellow w-2 md:w-4 lg:w-8 h-2 md:h-4 lg:h-8 -translate-y-1/2"></div>
          ))}
        </div>
        {skeletons.map((_, index) => (
          <div key={index} className={`mb-10 md:mb-16 flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} items-center animate-pulse`}>
            <div className="w-full md:w-5/12 px-2 py-2 md:px-4 md:py-6">
              <div className="relative">
                <div className="mt-8 md:mt-12 bg-gray-300 dark:bg-gray-700 rounded-lg shadow-lg w-full h-48 animate-pulse"></div>
                <div className="absolute bottom-8 left-0 right-0 transform translate-y-1/2 bg-accent dark:bg-accent-dark bg-opacity-90 rounded-lg p-2 md:p-4 text-primary dark:text-primary-dark backdrop-filter backdrop-blur-md transition-all duration-300">
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-4 animate-pulse"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2 animate-pulse"></div>
                  <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-5/6 animate-pulse"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonLoaderTimeline;
