import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faEnvelope,
  faCommentDots,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

const ContactForm = () => {
  // **State Variables**
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  // **Handle Input Changes**
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // Remove error message on input change
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
  };

  // **Validate Form Data**
  const validate = () => {
    let tempErrors = {};
    if (!formData.name.trim()) {
      tempErrors.name = 'Name is required';
    }
    if (!formData.email) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = 'Email is invalid';
    }
    if (!formData.message.trim()) {
      tempErrors.message = 'Message is required';
    }
    return tempErrors;
  };

  // **Handle Form Submission**
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      try {
        // Send form data to backend
        const response = await fetch('https://gavingizzie.com/api/contact.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();

        if (result.success) {
          setSubmissionStatus({
            success: true,
            message: 'Message sent successfully!',
          });
          setFormData({ name: '', email: '', message: '' });
        } else {
          setSubmissionStatus({
            success: false,
            message: result.message || 'Something went wrong. Please try again.',
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setSubmissionStatus({
          success: false,
          message: 'Something went wrong. Please try again.',
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <motion.form
      className="flex-1 p-8 bg-white dark:bg-gray-800 rounded-lg shadow-xl"
      onSubmit={handleSubmit}
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <h2 className="text-4xl font-bold mb-8 text-center text-gray-800 dark:text-gray-100">
        Get in Touch
      </h2>

      <div className="mb-6">
        <div className="relative">
          <FontAwesomeIcon
            icon={faUser}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            className={`w-full pl-10 pr-4 py-3 border ${
              errors.name ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
            } rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 ${
              errors.name ? 'focus:ring-red-500' : 'focus:ring-accent dark:focus:ring-accent'
            } transition`}
            aria-invalid={errors.name ? 'true' : 'false'}
            aria-describedby={errors.name ? 'name-error' : null}
          />
        </div>
        {errors.name && (
          <p id="name-error" className="mt-2 text-red-500 text-sm">
            {errors.name}
          </p>
        )}
      </div>

      <div className="mb-6">
        <div className="relative">
          <FontAwesomeIcon
            icon={faEnvelope}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            className={`w-full pl-10 pr-4 py-3 border ${
              errors.email ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
            } rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 ${
              errors.email ? 'focus:ring-red-500' : 'focus:ring-accent dark:focus:ring-accent'
            } transition`}
            aria-invalid={errors.email ? 'true' : 'false'}
            aria-describedby={errors.email ? 'email-error' : null}
          />
        </div>
        {errors.email && (
          <p id="email-error" className="mt-2 text-red-500 text-sm">
            {errors.email}
          </p>
        )}
      </div>

      <div className="mb-6">
        <div className="relative">
          <FontAwesomeIcon
            icon={faCommentDots}
            className="absolute left-3 top-4 text-gray-400"
          />
          <textarea
            name="message"
            id="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            className={`w-full pl-10 pr-4 py-3 border ${
              errors.message ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
            } rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 ${
              errors.message ? 'focus:ring-red-500' : 'focus:ring-accent dark:focus:ring-accent'
            } transition`}
            rows="5"
            aria-invalid={errors.message ? 'true' : 'false'}
            aria-describedby={errors.message ? 'message-error' : null}
          ></textarea>
        </div>
        {errors.message && (
          <p id="message-error" className="mt-2 text-red-500 text-sm">
            {errors.message}
          </p>
        )}
      </div>

      {submissionStatus && (
        <div
          className={`mb-6 p-4 rounded-md text-center ${
            submissionStatus.success ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }`}
        >
          {submissionStatus.message}
        </div>
      )}

      <div className="flex justify-center">
        <motion.button
          type="submit"
          className="w-full md:w-auto flex items-center justify-center bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel hover:from-orangePeel hover:to-accent text-white font-bold py-3 px-8 rounded-full shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-accent"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            'Sending...'
          ) : (
            <>
              Send Message
              <FontAwesomeIcon icon={faPaperPlane} className="ml-3" />
            </>
          )}
        </motion.button>
      </div>
    </motion.form>
  );
};

export default ContactForm;
