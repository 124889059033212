import React from 'react';
import { motion } from 'framer-motion';
import { FaBriefcase, FaCalendarAlt } from 'react-icons/fa';

const ProfessionalTimeline = () => {
  const events = [
    {
      year: 'July 2021 - August 2022',
      title: 'Shift Lead at Nothing Bundt Cakes',
      description:
        'Performed various roles including frosting, baking, guest services, and utility. Also led marketing efforts with outreach to local businesses.',
      image: '/TimelineImages/ProfessionalTimeline/img1.JPEG',
      icon: <FaBriefcase />,
    },
    {
      year: 'July 2022',
      title: 'Customer Service Representative at Citi',
      description:
        'Provided comprehensive support for credit card accounts, handling tasks such as processing payments, issuing replacement cards, and resolving customer disputes. Managed full-time work alongside school commitments.',
      image: '/TimelineImages/ProfessionalTimeline/img2.JPEG',
      icon: <FaBriefcase />,
    },
    {
      year: 'Present Date',
      title: 'Part-time Customer Service Representative at Citi',
      description:
        'Continue to assist customers with their accounts and address any issues, while accommodating my academic schedule.',
      image: '/TimelineImages/ProfessionalTimeline/img3.JPEG',
      icon: <FaBriefcase />,
    },
  ];

  return (
    <div className="container mx-auto py-12">
      <h2 className="text-5xl font-extrabold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel">
        Professional Timeline
      </h2>
      <div className="relative wrap overflow-hidden p-4 md:p-10 h-full">
        {/* Vertical timeline line */}
        <div className="hidden md:block absolute border-l-4 border-accent h-full left-1/2 transform -translate-x-1/2"></div>

        {events.map((event, index) => {
          // Determine if this is the last event
          const isLastEvent = index === events.length - 1;

          return (
            <motion.div
              key={index}
              className={`mb-8 flex justify-between items-center w-full ${
                index % 2 === 0
                  ? 'flex-col md:flex-row'
                  : 'flex-col md:flex-row-reverse'
              }`}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.3 }}
            >
              <div className="order-1 w-full md:w-5/12 px-1 py-4 flex justify-center">
                <div className="relative">
                  <div
                    className={`absolute inset-0 ${
                      isLastEvent ? 'rounded-lg' : 'rounded-full'
                    } bg-gradient-to-r from-accent via-hunyadiYellow to-orangePeel p-1`}
                  ></div>
                  <img
                    src={event.image}
                    alt={event.title}
                    className={`relative object-cover ${
                      isLastEvent
                        ? 'w-64 h-40 md:w-80 md:h-48 rounded-lg'
                        : 'w-48 h-48 md:w-64 md:h-64 rounded-full'
                    }`}
                  />
                </div>
              </div>
              <div className="z-20 flex items-center order-1 bg-accent shadow-xl w-8 h-8 rounded-full mt-4 md:mt-0">
                <div className="mx-auto text-white">{event.icon}</div>
              </div>
              <div className="order-1 w-full md:w-5/12 bg-secondary dark:bg-secondary-dark rounded-lg shadow-xl px-6 py-4 mt-4 md:mt-0">
                <h3 className="mb-2 text-2xl font-bold text-primary dark:text-primary-dark">
                  {event.title}
                </h3>
                <span className="text-sm font-medium text-muted dark:text-muted-dark">
                  <FaCalendarAlt className="inline mr-1" />
                  {event.year}
                </span>
                <p className="text-lg leading-snug mt-4 text-primary dark:text-primary-dark">
                  {event.description}
                </p>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfessionalTimeline;
