import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faBriefcase,
  faProjectDiagram,
  faPrint,
  faGamepad,
  faCamera,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import PersonalTimeline from './PersonalTimeline';
import ProfessionalTimeline from './ProfessionalTimeline';
import SkeletonLoaderTimeline from './SkeletonLoaderTimeline';
import GameModShowcase from './GameModShowcase';
import DPrintShowcase from './DPrintShowcase'; // Importing 3D Print Showcase
import { motion, AnimatePresence } from 'framer-motion';

const TimelineTabs = () => {
  const [selectedTab, setSelectedTab] = useState('professional');
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const tabs = [
    {
      id: 'personal',
      title: 'Personal',
      icon: faUser,
      disabled: true,
      content: <PersonalTimeline />,
    },
    {
      id: 'professional',
      title: 'Professional',
      icon: faBriefcase,
      content: <ProfessionalTimeline />,
    },
    {
      id: 'projects',
      title: 'Projects',
      icon: faProjectDiagram,
      subItems: [
        {
          id: '3d-printing',
          title: '3D Printing',
          icon: faPrint,
          content: <DPrintShowcase />,
        },
        {
          id: 'video-game-mod',
          title: 'Video Game Mod',
          icon: faGamepad,
          content: <GameModShowcase />,
        },
        {
          id: 'photography',
          title: 'Photography',
          icon: faCamera,
          disabled: true,
          content: (
            <div className="p-4">
              Content for Photography: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
          ),
        },
      ],
    },
  ];

  const renderContent = () => {
    if (selectedTab === 'projects' && selectedProject) {
      const project = tabs
        .find((tab) => tab.id === 'projects')
        .subItems.find((item) => item.id === selectedProject);
      return project?.content || <SkeletonLoaderTimeline />;
    } else {
      const tab = tabs.find((tab) => tab.id === selectedTab);
      return tab?.content || <SkeletonLoaderTimeline />;
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full">
      {/* Sidebar */}
      <div className="flex flex-col md:w-1/4 bg-gray-100 dark:bg-gray-900 p-6">
        <ul className="space-y-4">
          {tabs.map((tab) => (
            <li key={tab.id}>
              <button
                onClick={() => {
                  if (tab.disabled) return;
                  setSelectedTab(tab.id);
                  setSelectedProject(null);
                  setIsProjectsOpen(tab.id === 'projects' ? !isProjectsOpen : false);
                }}
                className={`flex items-center justify-between px-6 py-4 rounded-lg w-full text-xl font-medium transition-colors ${
                  selectedTab === tab.id && !selectedProject
                    ? 'bg-accent text-white'
                    : 'bg-secondary text-primary dark:text-primary-dark hover:bg-hover-accent dark:hover:bg-hover-accent-dark'
                } ${tab.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={tab.disabled}
              >
                <div className="flex items-center">
                  <FontAwesomeIcon icon={tab.icon} className="mr-3 w-5 h-5" />
                  {tab.title}
                </div>
                {tab.subItems && (
                  <FontAwesomeIcon
                    icon={isProjectsOpen ? faChevronUp : faChevronDown}
                    className="w-4 h-4"
                  />
                )}
              </button>
              {/* SubItems */}
              {tab.subItems && isProjectsOpen && selectedTab === 'projects' && (
                <AnimatePresence>
                  <motion.ul
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    className="overflow-hidden mt-2 ml-4 space-y-2"
                  >
                    {tab.subItems.map((item) => (
                      <li key={item.id}>
                        <button
                          onClick={() => {
                            if (item.disabled) return;
                            setSelectedProject(item.id);
                            setIsProjectsOpen(false);
                          }}
                          className={`flex items-center px-6 py-3 rounded-lg w-full text-lg transition-colors ${
                            selectedProject === item.id
                              ? 'bg-accent text-white'
                              : 'bg-secondary text-primary dark:text-primary-dark hover:bg-hover-accent dark:hover:bg-hover-accent-dark'
                          } ${item.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                          disabled={item.disabled}
                        >
                          <FontAwesomeIcon icon={item.icon} className="mr-3 w-4 h-4" />
                          {item.title}
                        </button>
                      </li>
                    ))}
                  </motion.ul>
                </AnimatePresence>
              )}
            </li>
          ))}
        </ul>
      </div>
      {/* Content Area */}
      <div className="flex-grow p-6 bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-gray-200 min-h-[400px]">
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedTab + selectedProject}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {renderContent()}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default TimelineTabs;
